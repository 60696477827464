import React, { useEffect, useState } from 'react';
import { LiveKitRoom, ControlButton } from 'livekit-react';
import { createLocalAudioTrack, createLocalVideoTrack, Room } from 'livekit-client';
// CSS should be explicitly imported if using the default UI
import 'livekit-react/dist/index.css';
// used by the default ParticipantView to maintain video aspect ratio.
// this CSS must be imported globally
// if you are using a custom Participant renderer, this import isn't necessary.
import "react-aspect-ratio/aspect-ratio.css";

async function onConnected(room: Room) {
  const audioTrack = await createLocalAudioTrack();
  await room.localParticipant.publishTrack(audioTrack);
  const videoTrack = await createLocalVideoTrack();
  await room.localParticipant.publishTrack(videoTrack, {
    simulcast: true,
    videoCodec: 'h264'
  });
}

const App = () => {
  const [url, setUrl] = useState<string>('wss://red-sea-1036.fly.dev:443');
  const [token, setToken] = useState<string>('');
  const [connectDisabled, setConnectDisabled] = useState<boolean>(true);
  const [connected, setConnected] = useState<boolean>(false);

  useEffect(() => {
    if (token && url) {
      setConnectDisabled(false)
    } else {
      setConnectDisabled(true)
    }
  }, [token, url]);

  if (!connected) {
    return (
      <fieldset>
        <label>URL <input type="text" name="url" value={url} onChange={e => setUrl(e.target.value)} /></label>
        <label>Token <input type="text" name="token" value={token} onChange={e => setToken(e.target.value)} /></label>
        <ControlButton
          label="Connect"
          disabled={connectDisabled}
          onClick={() => setConnected(true)} />
      </fieldset>
    );
  }
  
  return (
    <div className="roomContainer">
      <LiveKitRoom
        url={url}
        token={token}
        onConnected={room => onConnected(room)}
        onLeave={() => setConnected(false)} />
    </div>
  );
}

export default App;
